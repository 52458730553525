const CONFIG_KEY = 'JTC_EXTERNAL_LINKS';

function get(key) {
  return window.sessionStorage[key] || window[CONFIG_KEY][key];
}

export const HELP_URL = get('HELP_URL');
export const BROADBAND_SPEED_HELP_URL = get('BROADBAND_SPEED_HELP_URL');
export const PARTNER_HELP_URL = get('PARTNER_HELP_URL');
export const PARTNER_HELP_MENTORING_URL = get('PARTNER_HELP_MENTORING_URL');
export const CONTACT_URL = get('CONTACT_URL');
export const CLUBHOUSE_URL = get('CLUBHOUSE_URL');
export const SMART_METER_URL = get('SMART_METER_URL');
export const PRIVACY_URL = get('PRIVACY_URL');
export const COOKIES_URL = get('COOKIES_URL');
export const ROOT_TERMS_URL = get('ROOT_TERMS_URL');
export const GENERAL_TERMS_URL = get('GENERAL_TERMS_URL');
export const FIXED_ENERGY_TERMS_URL = get('FIXED_ENERGY_TERMS_URL');
export const LED_TERMS_URL = get('LED_TERMS_URL');
export const CBC_TERMS_URL = get('CBC_TERMS_URL');
export const INTERNATIONAL_CALLS_TERMS_URL = get(
  'INTERNATIONAL_CALLS_TERMS_URL'
);
export const BROADBAND_SPEED_CODE_OF_PRACTICE_URL = get(
  'BROADBAND_SPEED_CODE_OF_PRACTICE_URL'
);
export const TARIFF_INFO_URL = get('TARIFF_INFO_URL');
export const EVERYBODY_SAVES_URL = get('EVERYBODY_SAVES_URL');
export const MOBILE_TERMS = get('MOBILE_TERMS');
export const MOBILE_TARIFF_INFO = get('MOBILE_TARIFF_INFO');
export const MOBILE_FAIR_USE = get('MOBILE_FAIR_USE');
export const APPOINTMENT_PRESENTATION_URL = get('APPOINTMENT_PRESENTATION_URL');
export const BROADBAND_TARIFF_INFO_URL = get('BROADBAND_TARIFF_INFO_URL');
export const BUNDLE_BENEFITS_TERMS_URL = get('BUNDLE_BENEFITS_TERMS_URL');
export const CUSTOMER_APP_URL = get('CUSTOMER_APP_URL');
export const COOKIE_POLICY_URL = get('COOKIE_POLICY_URL');
export const EV_TARIFF_URL = get('EV_TARIFF_URL');
export const FIBRE_TARIFF_URL = get('FIBRE_TARIFF_URL');
export const FREE_ENERGY_CLUB_TERMS_URL = get('FREE_ENERGY_CLUB_TERMS_URL');
