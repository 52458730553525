import { getCookie } from 'app/redux/utils/cookie';

const MARKETING_PARAMS = [
  'campaign_id',
  'utm_campaign',
  'utm_medium',
  'utm_source',
  'utm_content',
  'utm_term',
  'click_id',
  'network',
];

const getCookieCampaignId = () => getCookie('campaignSource');

const getMarketingParams = (search) => {
  const query = new URLSearchParams(search);
  const urlParams = {};
  const cookieCampaignId = getCookieCampaignId();

  MARKETING_PARAMS.forEach((param) => {
    const value = query.get(param);

    if (value) {
      urlParams[param] = value;
    }
  });

  return { campaign_id: cookieCampaignId, ...urlParams };
};

export { getMarketingParams };
