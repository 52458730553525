export const CONFIG_KEY = 'JTC_APP_CONFIG';

export const APP_VERSION = process.env.APP_VERSION;
export const API_URL = window[CONFIG_KEY].API_URL;
export const APP_URL = window[CONFIG_KEY].APP_URL;
export const REMOTE_SESSION_SIGNALLING_ENDPOINT =
  window[CONFIG_KEY].REMOTE_SESSION_SIGNALLING_ENDPOINT;
export const MPARTICLE_KEY = window[CONFIG_KEY].MPARTICLE_KEY;
export const CONFIG_SENTRY_DSN = window[CONFIG_KEY].CONFIG_SENTRY_DSN;
export const SPREEDLY_TOKEN = window[CONFIG_KEY].SPREEDLY_TOKEN;
export const TRUSTPILOT_API_KEY = window[CONFIG_KEY].TRUSTPILOT_API_KEY;
export const TRUSTPILOT_BUSINESS_UNIT_ID =
  window[CONFIG_KEY].TRUSTPILOT_BUSINESS_UNIT_ID;
export const CBC_GQL_API_URI = window[CONFIG_KEY].CBC_GQL_API_URI;
export const ENVIRONMENT = window[CONFIG_KEY].ENVIRONMENT;
export const MPARTICLE_ENABLED = window[CONFIG_KEY].MPARTICLE_ENABLED;
export const GTM_KEY = window[CONFIG_KEY].GTM_KEY;
export const GTM_ENABLED = window[CONFIG_KEY].GTM_ENABLED;
export const SNOWPLOW_ENABLED = window[CONFIG_KEY].SNOWPLOW_ENABLED;
export const SNOWPLOW_INGEST_ENDPOINT =
  window[CONFIG_KEY].SNOWPLOW_INGEST_ENDPOINT;
export const ANALYTICS_ENVIRONMENT = window[CONFIG_KEY].ANALYTICS_ENVIRONMENT;
export const ORGANIC_RATIO = window[CONFIG_KEY].ORGANIC_RATIO;
export const CONFIG_BASE_URL = window[CONFIG_KEY].CONFIG_BASE_URL;
export const FEATURE_FLAGS_URL = window[CONFIG_KEY].FEATURE_FLAGS_URL;
export const SALES_AUTH_URL = window[CONFIG_KEY].SALES_AUTH_URL;
export const SALES_SEMAFONE = window[CONFIG_KEY].SALES_SEMAFONE;
export const RUDERSTACK_SOURCE_ID = window[CONFIG_KEY].RUDERSTACK_SOURCE_ID;
export const RUDERSTACK_WRITE_KEY = window[CONFIG_KEY].RUDERSTACK_WRITE_KEY;
export const RUDERSTACK_DATA_PLANE_URI =
  window[CONFIG_KEY].RUDERSTACK_DATA_PLANE_URI;
export const RUDERSTACK_SOURCE_CONFIG_URI =
  window[CONFIG_KEY].RUDERSTACK_SOURCE_CONFIG_URI;
export const ANALYTICS_RUDDERSTACK_ON =
  window[CONFIG_KEY].ANALYTICS_RUDDERSTACK_ON;
export const SWITCH_AND_SAVE_START_DATE =
  window[CONFIG_KEY].SWITCH_AND_SAVE_START_DATE;
export const SWITCH_AND_SAVE_END_DATE =
  window[CONFIG_KEY].SWITCH_AND_SAVE_END_DATE;
export const DAFFODIL_ENABLED = window[CONFIG_KEY].DAFFODIL_ENABLED;
export const ENERGY_TARIFF_DEFAULT = window[CONFIG_KEY].ENERGY_TARIFF_DEFAULT;
export const PAYMENT_BANK_TOKENISE_API =
  window[CONFIG_KEY].PAYMENT_BANK_TOKENISE_API;
export const ONETRUST_KEY = window[CONFIG_KEY].ONETRUST_KEY;
export const ONETRUST_ENABLED = window[CONFIG_KEY].ONETRUST_ENABLED;
export const CREDIT_RISK_PLATFORM_ENABLED =
  window[CONFIG_KEY].CREDIT_RISK_PLATFORM_ENABLED;
export const OPTIMIZELY_ENABLED = window[CONFIG_KEY].OPTIMIZELY_ENABLED;
export const OPTIMIZELY_SDK_KEY = window[CONFIG_KEY].OPTIMIZELY_SDK_KEY;
export const GOV_UK_API = window[CONFIG_KEY].GOV_UK_API;
export const APP_DURATION_MAX = parseInt(
  window[CONFIG_KEY].APP_DURATION_MAX,
  10
);
export const GQL_URI = window[CONFIG_KEY].GQL_URI;

export const MIXPANEL_ENABLED = window[CONFIG_KEY].MIXPANEL_ENABLED;
export const MIXPANEL_PROJECT_TOKEN = window[CONFIG_KEY].MIXPANEL_PROJECT_TOKEN;
