import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'lodash/fp';
import { withRouter } from 'react-router';
import { get } from 'lodash';
import { bindActionCreators } from 'redux';
import { useTracking } from '@utilitywarehouse/partner-tracking-react';
import * as actionCreators from 'redux/modules';
import { getStartedOrganic } from 'redux/modules/PartnerLogin/actions';
import { storePreselectParameters } from 'redux/modules/Preselect/Parameters/actions';
import PageTemplate from 'modules/layout/PageTemplate';
import LandingPageContainer from 'redux/modules/LandingPage/entry/container';
import JoiningTheClub from 'modules/LandingPage/MarketingJoiningTheClub';
import Tour from 'modules/LandingPage/VideoTour';
import VideoOverlay from 'modules/Shared/VideoOverlay';
import NewCBCChallengeDialog from 'modules/NewCashback/Challenge/Dialog';
import { STORE_NAME as ADDRESS_PICKER_STORE } from 'redux/modules/AddressPicker/constants';
import { STORE_NAME as ENTRY_FORM_STORE } from 'redux/modules/EntryForm/constants';
import { STORE_NAME as LANDING_PAGE_STORE } from 'redux/modules/LandingPage/constants';
import useStyles from './styles';
import { useHandleCTAConversionParam } from 'app/hooks/useCTAConversionParam';
import { getMarketingParams } from './getMarketingParams';

const LandingPage = (props) => {
  const {
    toggleHeroVideo,
    videoOpen,
    location,
    videoId,
    fieldValues,
    partnerLoggedIn,
    isRemote,
    getStartedOrganic,
    hasRestoreToken,
    newEntryOrganic,
    setServicePreselect,
    setExclusionParams,
    storePreselectParameters,
  } = props;
  useHandleCTAConversionParam();
  const analytics = useTracking();
  const showCBCChallengeDialog = get(location, 'hash') === '#cbc';
  const addressSelected = get(fieldValues, 'currentAddressSelected.uuid');
  const query = new URLSearchParams(location.search);
  const partnerPresent = query.get('partner_present');
  const leadCode = query.get('lead_code');
  const position = query.get('position') || null;
  const services = query.get('services');
  const exclude = query.get('exclude');
  const preselect = query.get('preselect');
  const marketingParams = getMarketingParams(location.search);
  const classes = useStyles();

  useEffect(() => {
    if (preselect) {
      storePreselectParameters();
    }
    const servicesArray = services?.split(',') || [];
    const excludeArray = exclude?.split(',') || [];
    if (servicesArray.length > 0) {
      setServicePreselect(servicesArray);
    }

    if (excludeArray.length > 0) {
      setExclusionParams(excludeArray);
    }

    if (partnerPresent === 'false') {
      getStartedOrganic(position, true, leadCode, marketingParams);
    }
  }, [
    services,
    setServicePreselect,
    setExclusionParams,
    exclude,
    getStartedOrganic,
    partnerPresent,
    analytics,
    position,
    leadCode,
    storePreselectParameters,
    preselect,
  ]);

  return (
    <PageTemplate
      helmetTitle="Join The Club"
      headerProps={props}
      footerProps={props}
    >
      <LandingPageContainer />
      <NewCBCChallengeDialog open={showCBCChallengeDialog} />
      {!hasRestoreToken && addressSelected === undefined && (
        <div
          id="video-joining-the-club-container"
          className={classes.videoContainer}
        >
          <VideoOverlay
            open={videoOpen}
            videoId={videoId}
            label="Video tour"
            toggleVideo={toggleHeroVideo}
          />
          {!partnerLoggedIn && !isRemote && (
            <Tour toggleHeroVideo={toggleHeroVideo} />
          )}
          {!newEntryOrganic && !partnerLoggedIn && !isRemote && (
            <JoiningTheClub />
          )}
        </div>
      )}
    </PageTemplate>
  );
};

LandingPage.propTypes = {
  fieldValues: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  toggleHeroVideo: PropTypes.func.isRequired,
  videoId: PropTypes.string.isRequired,
  videoOpen: PropTypes.bool.isRequired,
  partnerLoggedIn: PropTypes.bool.isRequired,
  isRemote: PropTypes.bool.isRequired,
  getStartedOrganic: PropTypes.func.isRequired,
  hasRestoreToken: PropTypes.bool,
  newEntryOrganic: PropTypes.bool.isRequired,
  setServicePreselect: PropTypes.func.isRequired,
  setExclusionParams: PropTypes.func.isRequired,
  storePreselectParameters: PropTypes.func.isRequired,
};

LandingPage.defaultProps = {
  cashBack: true,
};

const mapStateToProps = (state) => ({
  ...state.get(ADDRESS_PICKER_STORE).toJS(),
  ...state.get(ENTRY_FORM_STORE).toJS(),
  ...state.get(LANDING_PAGE_STORE).toJS(),
});

const bindActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      ...actionCreators.LandingPage,
      getStartedOrganic,
      storePreselectParameters,
    },
    dispatch
  );

export const TestLandingPage = LandingPage;

export default compose(
  withRouter,
  connect(mapStateToProps, bindActionsToProps)
)(LandingPage);
